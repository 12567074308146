<template>
<div>

    <!-- Media -->
    <b-media class="mb-2">
        <template #aside>
            <b-avatar ref="previewEl" :src="userData.avatar" :text="avatarText(userData.first_name)" :variant="`light-${resolveUserRoleVariant(userData.role_name)}`" size="90px" rounded />
        </template>
        <h4 class="mb-1">
            {{ userData.first_name }} {{ userData.middle_name }}

        </h4>
        <div class="card-text">{{ userData.email_address }} </div>
        <div class="card-text">{{ userData.msisdn }}</div>
        <span class="card-text">{{ userData.role_name }}</span>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
        <b-alert v-height-fade.appear :show="dismissCountDown" dismissible fade variant="danger" class="mb-0" @dismiss-count-down="countDownChanged">
            <div class="alert-body">
                <span>{{errorMessage}}</span>
            </div>
        </b-alert>
        <b-row>
            <b-col cols="12" md="4">
                <b-form-group label="Username" label-for="username">
                    <b-form-input id="username" v-model="userData.full_names" />
                </b-form-group>
            </b-col>

            <!-- Field: First Name -->
            <b-col cols="12" md="4">
                <b-form-group label="First Name" label-for="firstname">
                    <b-form-input id="firstname" v-model="userData.first_name" />
                </b-form-group>
            </b-col>

            <!-- Field: Middle Name -->
            <b-col cols="12" md="4">
                <b-form-group label="Middle Name" label-for="middle-name">
                    <b-form-input id="middle-name" v-model="userData.middle_name" />
                </b-form-group>
            </b-col>
            <!-- Field: Email -->
            <b-col cols="12" md="4">
                <b-form-group label="Last Name" label-for="last-name">
                    <b-form-input id="last-name" v-model="userData.email_address" type="email" />
                </b-form-group>
            </b-col>

            <!-- Last Name-->
            <b-col cols="12" md="4">
                <b-form-group label="Last Name" label-for="last-name">
                    <b-form-input id="last-name" v-model="userData.sur_name" type="text" />
                </b-form-group>
            </b-col>

            <!-- Field: Status -->
            <b-col cols="12" md="4">
                <b-form-group label="Status" label-for="user-status">
                    <v-select v-model="userData.account_status" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="statusOptions" :reduce="val => val.value" :clearable="false" input-id="user-status" />
                </b-form-group>
            </b-col>

            <!-- Field: Role -->
            <b-col cols="12" md="4">
                <b-form-group label="User Role" label-for="user-role">
                    <v-select v-model="userData.role_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="roleOptions" :reduce="val => val.value" :clearable="false" input-id="user-role" />
                </b-form-group>
            </b-col>

        </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click="updateInformation" :block="$store.getters['app/currentBreakPoint'] === 'xs'" id="editUser">
        <b-spinner small v-show="isLoading" />
        Save Changes
    </b-button>
   
</div>
</template>

<script>
import {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BSpinner,
    BAlert,
} from 'bootstrap-vue'
import {
    avatarText
} from '@core/utils/filter'
import vSelect from 'vue-select'
import {
    useInputImageRenderer
} from '@core/comp-functions/forms/form-utils'
import {
    ref
} from '@vue/composition-api'
import useUsersList from '../users-list/useUsersList'
import $ from 'jquery'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
    heightFade
} from '@core/directives/animations'
import Ripple from 'vue-ripple-directive'
export default {
    components: {
        BButton,
        BMedia,
        BAvatar,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BForm,
        BTable,
        BCard,
        BCardHeader,
        BCardTitle,
        BFormCheckbox,
        vSelect,
        BSpinner,
        BAlert,
    },
    props: {
        userData: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const {
            resolveUserRoleVariant
        } = useUsersList()

        // ? Demo Purpose => Update image on click of update
        const refInputEl = ref(null)
        const previewEl = ref(null)

        const {
            inputImageRenderer
        } = useInputImageRenderer(refInputEl, base64 => {
            props.userData.avatar = base64
        })

        return {
            resolveUserRoleVariant,
            avatarText,
            refInputEl,
            previewEl,
            inputImageRenderer,
        }
    },
    directives: {
        heightFade,
        Ripple,
    },
    data() {
        return {
            errorMessage: "",
            dismissSecs: 5,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            isLoading: false,
            roleOptions: [],
            statusOptions: [{
                    label: 'Pending',
                    value: '0'
                },
                {
                    label: 'Active',
                    value: '1'
                },
                {
                    label: 'Disabled',
                    value: '2'
                },
            ]
        }
    },
    mounted() {
        this.getRoles()
    },
    methods: {
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        getRoles() {
            let vm = this
            //clientData
            $.get({
                url: store.state.rootUrl + '/account/v1/view/user_roles',
                type: "get",
                async: true,
                crossDomain: true,
                headers: {
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                success: function (response, status, jQxhr) {
                    if (response.data.code == 200) {
                        let result = response.data.data.data;
                        for (let i = 0; i < result.length; i++) {
                            var roles = {
                                label: result[i].role_name,
                                value: result[i].role_id,
                            }
                            vm.roleOptions.push(roles)

                        }

                        console.log(JSON.stringify(vm.roleOptions))

                    }

                },
                error: function (jQxhr, status, error) {}
            });
        },
        updateInformation() {
            let vm = this;
            console.log("User data List " + JSON.stringify(vm.userData))
            vm.isLoading = true
            $('#editUser').html(' Please Wait ...');
            $.post({
                url: store.state.rootUrl + 'account/v1/edit_user',
                post: "POST",
                headers: {
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                data: JSON.stringify({
                    userMapId: vm.userData.user_mapId,
                    roleId: vm.userData.role_id,
                    emailAddress: vm.userData.email_address,
                    username: vm.userData.full_names,
                    firstName: vm.userData.first_name,
                    lastName: vm.userData.middle_name,
                    surName: vm.userData.sur_name,
                    status: vm.userData.account_status,
                }),
                success: function (response, status, jQxhr) {
                    vm.isLoading = false
                    $('#editUser').html(' Save Changes');
                    if (response.data.code != 200) {
                        vm.dismissCountDown = vm.dismissSecs
                        vm.showDismissibleAlert = true
                        vm.errorMessage = response.data.message

                    } else {
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'EditIcon',
                                variant: 'success',
                            },
                        })
                    }

                },
                error: function (jQxhr, status, error) {
                    vm.isLoading = false
                    vm.dismissCountDown = vm.dismissSecs
                    $('#editUser').html(' Save Changes');
                    var statustext = jQxhr.responseJSON.statusDescription;
                    vm.showDismissibleAlert = true;
                    if (!statustext) {
                        vm.errorMessage = "An error occurred. Try again later.";
                    } else {
                        vm.errorMessage = statustext + " " + errorMessage;
                    }
                }
            });
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
