<template>
<component :is="user === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="user === undefined">
        <h4 class="alert-heading">
            Error fetching user data
        </h4>
        <div class="alert-body">
            No user found with this user id. Check
            <b-link class="alert-link" :to="{ name: 'apps-users-list'}">
                User List
            </b-link>
            for other users.
        </div>
    </b-alert>

    <b-tabs v-if="user" pills>

        <!-- Tab: Account -->
        <b-tab active>
            <template #title>
                <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
                <span class="d-none d-sm-inline">Account</span>
            </template>
            <user-edit-tab-account :user-data="user" class="mt-2 pt-75" />
        </b-tab>

        <!-- Tab: Information -->
        <b-tab>
            <template #title>
                <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
                <span class="d-none d-sm-inline">User Information</span>
            </template>
            <user-edit-tab-information :user-data="user" class="mt-2 pt-75" />
        </b-tab>

        <!-- Tab: Social -->

    </b-tabs>
</component>
</template>

<script>
import {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
} from 'bootstrap-vue'
import {
    ref,
    onUnmounted
} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import userStoreModule from '../userStoreModule'
import UserEditTabAccount from './UserEditTabAccount.vue'
import UserEditTabInformation from './UserEditTabInformation.vue'
import UserEditTabSocial from './UserEditTabSocial.vue'
import $ from 'jquery'

export default {
    components: {
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,

        UserEditTabAccount,
        UserEditTabInformation,
        UserEditTabSocial,
    },
    setup() {
        const userData = ref(null)

        const USER_APP_STORE_MODULE_NAME = 'app-user'

        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
        })

        store.dispatch('app-user/fetchUser', {
                id: router.currentRoute.params.id
            })
            .then(response => {
                userData.value = response.data
            })
            .catch(error => {
                if (error.response.status === 404) {
                    userData.value = undefined
                }
            })

        return {
            userData,
        }
    },
    data() {
        return {
            user: {}
        }
    },
    mounted() {
        this.fetchRecord()
    },
    methods: {
        fetchRecord() {
            let vm = this
            $.get({
                url: store.state.rootUrl + 'account/v1/view/users',
                type: "get",
                async: true,
                crossDomain: true,
                data: {
                    limit: 10,
                    user_id: router.currentRoute.params.id
                },
                headers: {
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                success: function (response, status, jQxhr) {

                    vm.user = response.data.data.data[0]
                },
                error: function (jQxhr, status, error) {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Error fetching users list',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })
                }
            });

        },
         
    }
}
</script>

<style>

</style>
