import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import $ from 'jquery'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'user', sortable: true },
    { key: 'email', sortable: true },
    { key: 'contact', sortable: true },
    { key: 'role', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchUsers = (ctx, callback) => {
    console.log("am here "+$cookies.get("token"))
    let vm = this
    
    $.get({
        url: store.state.rootUrl + '/account/v1/view/users',
        type: "get",
        async: true,
        crossDomain: true,
        data:{
          limit:10
        },
        headers: {
            "X-Authorization-Key": vm.$cookies.get("token"),
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
        },
        success: function (response, status, jQxhr) {
          
          const { users, total } = response.data.data.data
          callback(users)
          totalUsers.value = total
        },
        error: function (jQxhr, status, error) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching users list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
    });
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'API User') return 'primary'
    if (role === 'Novice Account') return 'primary'
    if (role === 'Sales Agent') return 'warning'
    if (role === 'Digital Administrator') return 'success'
    if (role === 'Client Administrator') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'API User') return 'UserIcon'
    if (role === 'Digital Administrator') return 'SettingsIcon'
    if (role === 'Sales Agent') return 'Edit2Icon'
    if (role === 'Client Administrator') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status == '2') return 'warning'
    if (status == '1') return 'success'
    if (status == '3') return 'secondary'
    return 'primary'
  }

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
