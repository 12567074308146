<template>
<div>

    <!-- Header: Personal Info -->
    <div class="d-flex">
        <feather-icon icon="UserIcon" size="19" />
        <h4 class="mb-0 ml-50">
            View Permission for {{userData.first_name}}
        </h4>
    </div>

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
        <b-row>
            <b-table striped responsive :fields="columns" :items="permissions" class="mb-0">

                <template #cell(permission)="data">
                    {{ data.item.permission }}
                </template>
                <template #cell(status)="data">
                    <b-form-checkbox disbaled :checked="data.item.status | statusVerified" />
                </template>

            </b-table>
        </b-row>

        <!-- <b-row class="mt-2">
            <b-col>
                <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
                    Save Changes
                </b-button>
                <b-button variant="outline-secondary" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
                    Reset
                </b-button>
            </b-col>
        </b-row> -->
    </b-form>
</div>
</template>

<script>
import {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BTable,
    BFormCheckbox,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import {
    ref
} from '@vue/composition-api'
import vSelect from 'vue-select'
import $ from 'jquery'
import store from '@/store'
export default {
    components: {
        BRow,
        BCol,
        BForm,
        BFormGroup,
        flatPickr,
        BFormInput,
        vSelect,
        BFormRadioGroup,
        BFormCheckboxGroup,
        BButton,
        BTable,
        BFormCheckbox,
        
    },
    props: {
        userData: {
            type: Object,
            required: true,
        },

    },
    watch: {
        userData(newValue) {
            if (newValue) {
                this.getAllPermissions()
            }

        }
    },
    data() {
        return {
            user: {},
            permissions: [],
            columns: [{
                    key: 'permission',
                    sortable: true
                },
                {
                    key: 'status',
                },

            ]
        }
    },
    methods: {
        getAllPermissions() {
            let vm = this;
            $.get({
                url: store.state.rootUrl + 'account/v1/view/user_permissions',
                type: "get",
                async: true,
                crossDomain: true,
                data: {
                    userMapId: vm.userData.user_mapId
                },
                headers: {
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                success: function (response, status, jQxhr) {

                    vm.permissions = response.data.data
                },
                error: function (jQxhr, status, error) {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Error fetching users list',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })
                }
            });
        }
    },
    filters: {
        statusVerified(value){
            if(value == 1){
                return true
            }
            else {
                return false
            }
        },
     }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
